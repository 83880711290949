<template>
  <div class="operational-item" v-for="item in data" :key="item">
    <div :style="{ color: colors[0] }" class="operational-item-title">{{ item.label }}</div>
    <div
      :style="{ color: colors[1], fontWeight: 'bold', paddingTop: '10px' }"
      class="operational-item-body"
      :class="{'operational-item-body-show': item.show }"
    >
      <span v-if="item.show === 'hide'">******</span>
      <span class="operational-item-span" v-else>{{ item.value }}</span>
      <el-image
        v-if="item.show"
        class="operational-item-body-img"
        :src="item.show === 'hide' ? showImg : hideImg"
        fit="fill"
        @click="item.show = item.show === 'show' ? 'hide' : 'show'"
      ></el-image>
    </div>
  </div>
</template>

<script>
import showImg from '../../../assets/image/show.png'
import hideImg from '../../../assets/image/hide.png'
export default {
  name: 'OperationalItem',
  components: {},
  props: {
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    colors: {
      type: Array,
      required: false,
      default: () => ['#fff', '#00FCFF']
    }
  },
  setup() {
    return { showImg, hideImg }
  }
}
</script>

<style lang="scss">
.operational-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  padding: 10px 5px;
  width: 25%;
  box-sizing: border-box;
  position: relative;
  &-body {
    display: flex;
    align-items: center;
    &-img {
      width: 14px;
      margin-left: 5px;
      // position: absolute;
      // right: 10px;
    }
    &-show {
      padding-left: 20px;
    }
  }

  &-span{
    font-size:16px;
  }
}
</style>
