// import { isToFixed } from '../index'

export default (data = []) => {
  // 1
  const colors = [
    ['#00FCFF', '#435CFE'],
    ['#1FD98C', '#34B340'],
    ['#FFCE72', '#FFA903'],
    ['#FFDE00', '#FF6353']
  ]
  let dataOne = [0, 0, 0, 0]
  if (data.length > 0) {
    const { levelCount1, levelCount2, levelCount3, levelCount4 } = data[0]
    dataOne = [levelCount1 || 0, levelCount2 || 0, levelCount3 || 0, levelCount4 || 0]
  }
  const xAxisData = ['轻微', '一般', '严重', '灾难']
  // data.map(item => {
  //   xAxisData.push(item.name)
  //   dataOne.push(item.completeCount)
  //   dataTow.push(item.undoErrorCount)
  // })

  return {
    // title: {
    //   text: '（条）',
    //   x: '10',
    //   y: '10',
    //   textStyle: {
    //     fontWeight: 'normal',
    //     fontSize: 12,
    //     color: '#fff'
    //   }
    // },
    grid: {
      top: '10%',
      // left: '10%',
      // right: '2%',
      left: '35px',
      right: '20px',
      bottom: '30px',
      // containLabel: true
    },
    tooltip: {
      show: 'true',
      trigger: 'axis',
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
        shadowStyle: {
          color: 'rgba(112,112,112,0)'
        }
      },
      formatter: params => {
        let div = ''
        params.map(item => {
          if (['轻微', '一般', '严重', '灾难'].includes(item.name)) {
            div = `${item.name}:${item.data}`
          }
        })
        return div
      },
      padding: [8, 10], //内边距
      extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);' //添加阴影
    },
    xAxis:[{
      type: 'value',
      minInterval: 1,
      axisTick: {
        show: false
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: '#32346c'
        }
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: '#32346c '
        }
      },
      axisLabel: {
        textStyle: {
          color: '#bac0c0',
          fontWeight: 'normal',
          fontSize: '12'
        }
      }
    },{
      type: 'value',
      minInterval: 1,
      axisTick: {
        show: false
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: '#32346c'
        }
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: '#32346c '
        }
      },
      axisLabel: {
        textStyle: {
          color: '#bac0c0',
          fontWeight: 'normal',
          fontSize: '12'
        }
      }
    }],
    yAxis: [
      {
        type: 'category',
        axisTick: {
          show: false
        },
        min: 0,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#fff'
          }
        },
        axisLabel: {
          inside: false,
          interval: 0,
          rotate: 0,
          textStyle: {
            color: '#fff',
            fontWeight: 'normal',
            fontSize: '12'
          }
        },
        data: xAxisData
      },
      {
        type: 'category',
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        splitArea: {
          show: false
        },
        splitLine: {
          show: false
        },
        data: xAxisData
      }
    ],
    series: [
      {
        type: 'bar',
        barWidth: 0,
        label: {
          normal: {
            show: true,
            position: 'right',
            offset:[5,8],
            formatter: e => {
              return e.value === 0 ? '' : e.value
            },
            fontSize: 12,
            color: '#fff',
            distance: 0,
            backgroundColor: 'transparent',
            padding: 0,
            borderRadius: 50
            // offset: [4, -5]
          }
        },
        itemStyle: {
          normal: {
            color: 'transparent'
          }
        },
        data: dataOne
      },
      {
        type: 'bar',
        barWidth: 10,
        barGap: '220%',
        xAxisIndex: 1,
        label: {
          show: false,
          formatter: '{a|}',
          // color: '#ff0000',
          position: 'right',
          distance: -10,
          backgroundColor: '#fff',
          padding: 2,
          borderRadius: 15,
          rich: {
            a: {
              width: 4,
              height: 4,
              borderRadius: 4,
              lineHeight: 4,
              backgroundColor: '#fff'
            }
          }
        },
        itemStyle: {
          normal: {
            barBorderRadius: [0, 4, 4, 0],
            position: 'right',
            color: params => {
              return {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: colors[params.dataIndex][0]
                  },
                  {
                    offset: 1,
                    color: colors[params.dataIndex][1]
                  }
                ]
              }
            }
          }
        },
        data: dataOne
      }
    ]
  }
}
