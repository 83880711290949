export default (data = [], type = 'a') => {
  let nData = []
  let count = 0
  let title = ''
  const color = ['#E6004D', '#1FD98C', '#00FCFF', '#FFCE72', '#435CFE']
  const color1 = ['#FFCE72', '#435CFE', '#00FCFF', '#E6004D', '#1FD98C']
  const colors = type === 'a' ? color : color1
  if (data.length > 0) {
    const { undoCount, completeCount, errorCount } = data[0] || []
    count = type === 'a' ? errorCount : 0
    // title = type === 'a' ? '故障总数' : '工单统计'
    title = type === 'a' ? '故障总数' : '巡检任务'
    nData =
      type === 'a'
        ? [
            { name: '未办结', value: undoCount },
            { name: '已办结', value: completeCount }
          ]
        : data.map(item => {
            count += item.total

            const plan = '计划'
            const name = item.planType
            let result = name
            if(name.indexOf(plan)!==-1){
              const str = name.split(plan)
              result = str[0]
            }
            return { name: result, value: item.total }
          })
  }

  return {
    legend: {
      bottom: '0%',
      left: 'left',
      itemWidth:10,
      itemHeight:10,
      textStyle:{
        color:'white',
        fontSize:'10px'
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b} : {c}'
    },
    title: [
      {
        text: `{val|${count || ''}}\n{name|${title}}`,
        bottom: '45%',
        left: 'center',
        textStyle: {
          rich: {
            name: {
              fontSize: 12,
              color: '#fff',
              padding: [8, 0]
            },
            val: {
              fontSize: 14,
              fontEight: 'bold',
              color: '#fff'
            }
          }
        }
      }
    ],
    color: colors,
    series: [
      {
        // name: 'Nightingale Chart',
        type: 'pie',
        bottom:'15%',
        radius: [30, 55],
        center: ['50%', '50%'],
        // roseType: 'area',
        label: {
          show: false,
          formatter: p => `${p.value}\n${p.name}`,
          color: '#fff',
          fontSize: 12
        },
        emphasis: {
          label: {
            show: false
          }
        },
        itemStyle: {
          borderRadius: 3
        },
        startAngle: 170,
        data: nData
      }
    ]
  }
}
